import Link from "next/link";

export default function notFound() {
  return (
    <div className="flex items-center justify-center h-screen w-full bg-white flex-col">
      <h1 className="text-black block text-2xl text-center mt-8 font-bold">
        404 Página no encontrada
      </h1>

      <Link href={"/"}>
        &#x2190; Regresar al inicio
      </Link>
    </div>
  );
}
